import React, { useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useShopify, useWindowDimensions, useApp } from "~hooks";
import { Grid, Go } from "~components";
import { MEDIA_QUERIES } from "~utils/css";
import { GRID_PADDING_REM } from "~components/_common/Grid/index.jsx";

import { ReactComponent as LogoMark } from "~assets/icons/logomark.svg";

/** ============================================================================
 * @component
 * Global nav.
 */
const Container = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 40;
  width: 100%;
  position: fixed;
  top: 0px;
  right: 0px;
  left: 0px;
  pointer-events: none;
`;

const Header = () => {
  const { sanityGlobals, allSanityShopifyProduct } = useStaticQuery(graphql`
    query Header {
      sanityGlobals {
        headerLinks {
          text
          url
        }
        footerLinks {
          text
          url
        }
      }

      allSanityShopifyProduct {
        edges {
          node {
            id

            category {
              title
            }
          }
        }
      }
    }
  `);

  const { headerLinks, footerLinks } = sanityGlobals;

  const [categoriesActive, setCategoriesActive] = useState();

  const { cart, cartActive, setCartActive } = useShopify();
  const { isDesktop } = useWindowDimensions();
  const { menuActive, setMenuActive } = useApp();

  const categories = [];

  const categoryOrder = [`Self Care Tools`, `Wearable Activism`];

  allSanityShopifyProduct.edges.forEach(({ node }) => {
    if (!node?.category?.title || categories?.includes(node.category.title)) {
      return;
    }

    categories.push(node.category.title);
  });

  categories.sort(
    (a, b) => categoryOrder.indexOf(a) - categoryOrder.indexOf(b)
  );

  //

  return (
    <Container>
      <Grid node="article">
        <nav
          css={css`
            grid-column: span 12 / span 12;
            height: 2.5rem;
            position: relative;
            display: flex;
            align-items: center;
            margin-top: calc(${GRID_PADDING_REM}rem / 2);
            padding: 0 0.85rem;
            background: var(--color-white);
            box-shadow: 0px 2px 5px #00000026;
            pointer-events: auto;

            ${MEDIA_QUERIES?.desktop} {
              margin-top: calc(${GRID_PADDING_REM}rem);
              height: 3rem;
              padding: 0 1.75rem;
            }
          `}
        >
          {isDesktop && (
            <div
              css={css`
                width: 33.33333%;
                height: 100%;
                position: relative;
              `}
            >
              <ul
                css={css`
                  transition: 0.3s var(--cubic-easing) opacity,
                    0.3s var(--cubic-easing) transform;

                  opacity: ${categoriesActive ? `1` : `0`};
                  pointer-events: ${categoriesActive ? `auto` : `none`};
                  transform: translate3d(
                    ${categoriesActive ? `0` : `-1rem`},
                    0,
                    0
                  );

                  width: 100%;
                  height: 100%;
                  position: absolute;
                  top: 0;
                  bottom: 0;
                  left: 0;
                  z-index: 999;
                  display: flex;
                  align-items: center;
                  justify-content: start;
                  background: white;
                  color: black;
                `}
                onMouseLeave={() => {
                  setCategoriesActive(false);
                }}
              >
                {categories.map((category) => (
                  <li
                    key={`header-category-${category}`}
                    css={css`
                      margin-right: 1.75rem;
                    `}
                  >
                    <Go
                      to={`/shop?category=${category}`}
                      _css={[
                        css`
                          ${MEDIA_QUERIES?.hoverable} {
                            &:hover {
                              text-decoration: underline;
                            }
                          }
                        `
                      ]}
                    >
                      <span className="text-button">{category}</span>
                    </Go>
                  </li>
                ))}

                <li
                  css={css`
                    margin-right: 1.75rem;
                  `}
                >
                  <Go
                    to="/shop"
                    _css={[
                      css`
                        ${MEDIA_QUERIES?.hoverable} {
                          &:hover {
                            text-decoration: underline;
                          }
                        }
                      `
                    ]}
                    onMouseEnter={() => {
                      setCategoriesActive(true);
                    }}
                  >
                    <span className="text-button">All</span>
                  </Go>
                </li>
              </ul>

              <ul
                css={css`
                  transition: 0.3s var(--cubic-easing) opacity,
                    0.3s var(--cubic-easing) transform;

                  opacity: ${categoriesActive ? `0` : `1`};
                  pointer-events: ${categoriesActive ? `none` : `auto`};
                  transform: translate3d(
                    ${categoriesActive ? `-1rem` : `0`},
                    0,
                    0
                  );

                  width: 100%;
                  height: 100%;
                  position: relative;
                  display: flex;
                  align-items: center;
                  justify-content: start;
                `}
              >
                <li
                  css={css`
                    height: 100%;
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-right: 1.75rem;
                  `}
                >
                  <div
                    css={[
                      css`
                        ${MEDIA_QUERIES?.hoverable} {
                          &:hover {
                            text-decoration: underline;
                          }
                        }
                      `
                    ]}
                    onMouseEnter={() => {
                      setCategoriesActive(true);
                    }}
                  >
                    <h3 className="text-button">Shop</h3>
                  </div>
                </li>

                {headerLinks?.[0] &&
                  headerLinks.map((headerLink) => {
                    let currentPath;

                    if (typeof window !== `undefined`) {
                      currentPath =
                        headerLink?.url === window.location.pathname;
                    }

                    return (
                      <li
                        key={`header-link-${headerLink?.text}`}
                        css={css`
                          height: 100%;
                          position: relative;
                          display: flex;
                          align-items: center;
                          justify-content: center;
                          margin-right: 1.75rem;
                        `}
                      >
                        <Go
                          to={headerLink?.url}
                          onClick={() => {
                            if (currentPath) {
                              window.location.reload();
                            }
                          }}
                          _css={css`
                            ${MEDIA_QUERIES?.hoverable} {
                              &:hover {
                                text-decoration: underline;
                              }
                            }
                          `}
                        >
                          <h3 className="text-button">{headerLink.text}</h3>
                        </Go>
                      </li>
                    );
                  })}
              </ul>
            </div>
          )}

          {!isDesktop && (
            <div
              css={[
                css`
                  width: 25%;
                  height: 0.75rem;
                  display: flex;
                  justify-content: flex-start;
                `
              ]}
            >
              <button
                css={[
                  css`
                    width: 1.25rem;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                  `
                ]}
                type="button"
                onClick={() => {
                  setMenuActive(!menuActive);
                  setCartActive(false);
                }}
              >
                {Array(3).fill(
                  <span
                    css={[
                      css`
                        width: 100%;
                        border-top: 1px solid black;
                      `
                    ]}
                  />
                )}
              </button>
            </div>
          )}

          <div
            css={css`
              width: 50%;
              position: relative;
              display: flex;
              align-items: center;
              justify-content: center;

              ${MEDIA_QUERIES?.desktop} {
                width: 33.33333%;
                height: 100%;
              }
            `}
          >
            <Go
              to="/"
              _css={css`
                height: 100%;
                position: relative;
                margin-top: 0.2rem;
                display: flex;
                align-items: center;
                justify-content: center;
              `}
            >
              <LogoMark
                css={css`
                  height: 0.8rem;

                  ${MEDIA_QUERIES?.desktop} {
                    height: 1rem;
                  }
                `}
              />
            </Go>
          </div>

          <div
            css={css`
              width: 25%;
              display: flex;
              align-items: center;
              justify-content: flex-end;

              ${MEDIA_QUERIES?.desktop} {
                width: 33.33333%;
              }
            `}
          >
            <button
              type="button"
              onClick={() => {
                setCartActive(!cartActive);
                setMenuActive(false);
              }}
            >
              <h3 className={isDesktop ? `text-button` : `text-header-button`}>
                <span>Bag</span>

                {cart?.[0] && <span> ({cart?.length})</span>}
              </h3>
            </button>
          </div>
        </nav>

        {!isDesktop && (
          <nav
            css={[
              css`
                transition: 0.5s var(--cubic-easing) height;

                grid-column: span 12 / span 12;
                height: ${menuActive
                  ? `calc(var(--browser-height) - 6rem)`
                  : `0%`};
                max-height: 600px;
                background-color: var(--color-white);
                position: relative;
                overflow-y: scroll;
                pointer-events: ${menuActive ? `auto` : `none`};

                ${!menuActive && `box-shadow: 0px 2px 5px #00000026;`}

                ${MEDIA_QUERIES?.desktop} {
                  height: ${menuActive ? `100%` : `0%`};
                }
              `
            ]}
          >
            <div
              css={[
                css`
                  height: 100%;
                  padding: 1.75rem 0.85rem 0.85rem;
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                `
              ]}
            >
              <ul
                css={css`
                  height: 100%;
                  position: relative;
                  display: flex;
                  flex-direction: column;
                  gap: 0.667rem;
                `}
              >
                <li
                  css={css`
                    margin-right: 1.75rem;
                  `}
                >
                  <button
                    type="button"
                    onClick={() => setCategoriesActive(!categoriesActive)}
                  >
                    <h3 className="b1">Shop</h3>
                  </button>
                </li>

                {categoriesActive &&
                  categories.map((category, categoryIndex) => (
                    <li
                      key={`header-category-xs-${category}`}
                      css={css`
                        animation: var(--animation-appear-right);
                        animation-delay: ${categoryIndex * 100}ms;

                        transform: translate3d(-1rem, 0, 0);
                        opacity: 0;

                        margin-left: 1rem;
                      `}
                    >
                      <Go to={`/shop?category=${category}`}>
                        <span className="b1">{category}</span>
                      </Go>
                    </li>
                  ))}

                {/* {categoriesActive && (
                  <li
                    css={css`
                      margin-right: 1.75rem;
                    `}
                  >
                    <Go to="/shop">
                      <h3 className="b1">Self Care Tools</h3>
                    </Go>
                  </li>
                )}

                {categoriesActive && (
                  <li
                    css={css`
                      margin-right: 1.75rem;
                    `}
                  >
                    <Go to="/shop">
                      <h3 className="b1">Wearable Activism</h3>
                    </Go>
                  </li>
                )} */}

                {headerLinks?.[0] &&
                  headerLinks.map((headerLink) => {
                    let currentPath;

                    if (typeof window !== `undefined`) {
                      currentPath =
                        headerLink?.url === window.location.pathname;
                    }

                    //

                    return (
                      <li
                        key={`mobile-header-link-${headerLink?.text}`}
                        css={css`
                          margin-right: 1.75rem;
                        `}
                      >
                        <Go
                          to={headerLink?.url}
                          onClick={() => {
                            if (currentPath) {
                              window.location.reload();
                            }
                          }}
                        >
                          <h3 className="b1">{headerLink.text}</h3>
                        </Go>
                      </li>
                    );
                  })}
              </ul>

              <ul
                css={css`
                  position: relative;
                  display: flex;
                  flex-direction: column;
                  gap: 0.75rem;
                `}
              >
                {footerLinks?.[0] &&
                  footerLinks
                    .filter(
                      (footerLink) =>
                        !headerLinks.some(
                          (headerLink) => headerLink.text === footerLink.text
                        )
                    )
                    .map((footerLink) => (
                      <li
                        key={`mobile-footer-link-${footerLink?.text}`}
                        css={css`
                          margin-right: 1.75rem;
                        `}
                      >
                        <Go to={footerLink?.url}>
                          <h3 className="b1">{footerLink.text}</h3>
                        </Go>
                      </li>
                    ))}
              </ul>
            </div>
          </nav>
        )}
      </Grid>
    </Container>
  );
};

export default Header;
